@import "https://fonts.googleapis.com/css2?family=Exo:wght@200;400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Metal+Mania:wght@400;600&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.right-6 {
  right: 1.5rem;
}

.right-0 {
  right: 0;
}

.top-12 {
  top: 3rem;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-5 {
  right: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.-top-2 {
  top: -.5rem;
}

.-right-2 {
  right: -.5rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.left-5 {
  left: 1.25rem;
}

.top-5 {
  top: 1.25rem;
}

.bottom-1 {
  bottom: .25rem;
}

.top-1 {
  top: .25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[100px\] {
  bottom: 100px;
}

.left-1 {
  left: .25rem;
}

.right-1 {
  right: .25rem;
}

.bottom-3 {
  bottom: .75rem;
}

.left-3 {
  left: .75rem;
}

.right-3 {
  right: .75rem;
}

.right-2 {
  right: .5rem;
}

.top-2 {
  top: .5rem;
}

.-right-5 {
  right: -1.25rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.bottom-2 {
  bottom: .5rem;
}

.left-2 {
  left: .5rem;
}

.top-3 {
  top: .75rem;
}

.-left-1 {
  left: -.25rem;
}

.-top-1 {
  top: -.25rem;
}

.left-\[-84px\] {
  left: -84px;
}

.bottom-8 {
  bottom: 2rem;
}

.right-24 {
  right: 6rem;
}

.top-8 {
  top: 2rem;
}

.left-1\/2 {
  left: 50%;
}

.top-1\.5 {
  top: .375rem;
}

.left-16 {
  left: 4rem;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[100\%\] {
  left: 100%;
}

.left-\[20\%\] {
  left: 20%;
}

.top-\[20\%\] {
  top: 20%;
}

.right-8 {
  right: 2rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.left-4 {
  left: 1rem;
}

.right-16 {
  right: 4rem;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-0 {
  z-index: 0;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.order-last {
  order: 9999;
}

.order-1 {
  order: 1;
}

.m-3 {
  margin: .75rem;
}

.m-2 {
  margin: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-1 {
  margin-right: .25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mr-8 {
  margin-right: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-7 {
  height: 1.75rem;
}

.h-36 {
  height: 9rem;
}

.h-12 {
  height: 3rem;
}

.h-8 {
  height: 2rem;
}

.h-4 {
  height: 1rem;
}

.h-auto {
  height: auto;
}

.h-0 {
  height: 0;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-full {
  height: 100%;
}

.h-\[900px\] {
  height: 900px;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-24 {
  height: 6rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-80 {
  height: 20rem;
}

.h-\[11\.9rem\] {
  height: 11.9rem;
}

.h-\[270px\] {
  height: 270px;
}

.h-\[14\.65rem\] {
  height: 14.65rem;
}

.h-20 {
  height: 5rem;
}

.h-16 {
  height: 4rem;
}

.h-\[196px\] {
  height: 196px;
}

.h-6 {
  height: 1.5rem;
}

.h-\[198px\] {
  height: 198px;
}

.h-1 {
  height: .25rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-\[340px\] {
  max-height: 340px;
}

.max-h-\[360px\] {
  max-height: 360px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[250px\] {
  min-height: 250px;
}

.w-\[400px\] {
  width: 400px;
}

.w-5 {
  width: 1.25rem;
}

.w-7 {
  width: 1.75rem;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-full {
  width: 100%;
}

.w-56 {
  width: 14rem;
}

.w-40 {
  width: 10rem;
}

.w-12 {
  width: 3rem;
}

.w-3\/4 {
  width: 75%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-14 {
  width: 3.5rem;
}

.w-4 {
  width: 1rem;
}

.w-10 {
  width: 2.5rem;
}

.w-20 {
  width: 5rem;
}

.w-28 {
  width: 7rem;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-60 {
  width: 15rem;
}

.w-48 {
  width: 12rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-52 {
  width: 13rem;
}

.w-auto {
  width: auto;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-16 {
  width: 4rem;
}

.w-\[140px\] {
  width: 140px;
}

.w-6 {
  width: 1.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-64 {
  width: 16rem;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[50px\] {
  width: 50px;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-72 {
  width: 18rem;
}

.min-w-\[90px\] {
  min-width: 90px;
}

.max-w-\[30px\] {
  max-width: 30px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.flex-1 {
  flex: 1;
}

.grow {
  flex-grow: 1;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-full {
  flex-basis: 100%;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[-1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-end {
  place-items: end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-4 {
  gap: 1rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-\[4px\] {
  border-top-width: 4px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-gray-600\/30 {
  border-color: #4b55634d;
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.border-gray-700\/30 {
  border-color: #3741514d;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-mainLight {
  --tw-border-opacity: 1;
  border-color: rgb(29 18 94 / var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.border-violet-300\/10 {
  border-color: #c4b5fd1a;
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-mainLight\/80 {
  border-color: #1d125ecc;
}

.border-orange-500\/80 {
  border-color: #f97316cc;
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-white\/20 {
  border-color: #fff3;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-mainSkyBlue\/80 {
  border-color: #39306fcc;
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: #ffffff1a;
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-main\/95 {
  background-color: #0c0635f2;
}

.bg-sky-100\/95 {
  background-color: #e0f2fef2;
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.bg-main {
  --tw-bg-opacity: 1;
  background-color: rgb(12 6 53 / var(--tw-bg-opacity));
}

.bg-mainLight {
  --tw-bg-opacity: 1;
  background-color: rgb(29 18 94 / var(--tw-bg-opacity));
}

.bg-main\/50 {
  background-color: #0c063580;
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-main\/80 {
  background-color: #0c0635cc;
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-mainLight\/50 {
  background-color: #1d125e80;
}

.bg-mainLight\/70 {
  background-color: #1d125eb3;
}

.bg-mainLight\/60 {
  background-color: #1d125e99;
}

.bg-main\/70 {
  background-color: #0c0635b3;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-mainLight\/30 {
  background-color: #1d125e4d;
}

.bg-orange-400\/10 {
  background-color: #fb923c1a;
}

.bg-gray-900\/30 {
  background-color: #0707074d;
}

.bg-main\/30 {
  background-color: #0c06354d;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-mainSkyBlue\/30 {
  background-color: #39306f4d;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-\[\#0e0737\] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 7 55 / var(--tw-bg-opacity));
}

.bg-\[\#140E38\]\/95 {
  background-color: #140e38f2;
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-500\/20 {
  background-color: #f9731633;
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-cover {
  background-size: cover;
}

.fill-red-500 {
  fill: #ef4444;
}

.fill-blue-600 {
  fill: #2563eb;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-3 {
  padding: .75rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-1 {
  padding: .25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-0 {
  padding-top: 0;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.font-\[Exo\] {
  font-family: Exo;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-tight {
  line-height: 1.25;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-10 {
  line-height: 2.5rem;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-\[\#8f8f8f\] {
  --tw-text-opacity: 1;
  color: rgb(143 143 143 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white\/40 {
  color: #fff6;
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-white\/50 {
  color: #ffffff80;
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-violet-300\/20 {
  color: #c4b5fd33;
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-70 {
  opacity: .7;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-80 {
  opacity: .8;
}

.opacity-40 {
  opacity: .4;
}

.opacity-20 {
  opacity: .2;
}

.opacity-50 {
  opacity: .5;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_20px_\#f97315a8_inset\] {
  --tw-shadow: 0 0 20px #f97315a8 inset;
  --tw-shadow-colored: inset 0 0 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-blue-500\/50 {
  --tw-shadow-color: #3b82f680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/50 {
  --tw-shadow-color: #ef444480;
  --tw-shadow: var(--tw-shadow-colored);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  letter-spacing: .02em;
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
  background-color: #090029;
  background-size: cover;
  margin: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.35;
}

.zombie-font {
  font-family: Metal Mania, sans-serif;
}

.exo-font {
  font-family: Exo, sans-serif;
}

.landing-bg {
  background: url("body-bg.6107bbf5.webp") top / cover repeat-y;
}

.top-block {
  background: url("hero-bg1.a500aa0f.webp") top / contain no-repeat;
}

.title-shadow {
  text-shadow: 3px 3px #893ebb;
}

.active {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.landing-bg .active {
  background-color: #0000;
}

.page-item, .page-item.active {
  background-color: #0000;
  margin-right: .5rem;
  font-weight: 600;
  display: inline-block;
}

.page-item .page-link {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 9999px;
  padding-top: .5rem;
  display: inline-block;
}

.page-item.active .page-link {
  --tw-bg-opacity: 1;
  background-color: rgb(6 94 136 / var(--tw-bg-opacity));
}

.timeline-body {
  width: 100%;
  z-index: 2;
  position: relative;
}

.mint-success {
  cursor: pointer;
  background: #34336d;
}

.mint-success:hover {
  background-color: #373577;
}

#landing a, a.link {
  color: orange;
}

#landing a:hover, a.link:hover {
  border-bottom: 2px dotted orange;
}

.move-discovery-icon {
  animation: 3s linear infinite alternate move_discovery_icon;
}

@keyframes move_discovery_icon {
  0% {
    top: 20%;
    left: 20%;
  }

  25% {
    top: 20%;
    left: 60%;
  }

  50% {
    top: 56%;
    left: 20%;
  }

  75% {
    top: 40%;
    left: 50%;
  }

  100% {
    left: 20%;
  }
}

.rotate-card {
  transform-style: preserve-3d;
  animation: 2s ease-in alternate rotate_zoom_card;
}

@keyframes rotate_zoom_card {
  80% {
    opacity: .3;
    transform: rotateZ(360deg)scale(.5);
  }

  100% {
    opacity: .1;
    transform: rotateZ(500deg)scale(.2);
  }
}

.rotate-card-reverse {
  transform-style: preserve-3d;
  animation: .6s ease-in alternate rotate_zoom_card_reverse;
}

@keyframes rotate_zoom_card_reverse {
  0% {
    opacity: 0;
    transform: rotateZ(0)scale(0);
  }

  100% {
    opacity: 1;
    transform: rotateZ(-360deg)scale(1);
  }
}

.discovery-text-results {
  transform-style: preserve-3d;
  animation: .4s ease-in alternate move_left_text;
}

@keyframes move_left_text {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 360px;
  }
}

.heading-tertiary {
  color: inherit;
  font-size: 1.2rem;
  font-weight: bolder;
}

.staking-monster-light {
  box-shadow: 0 0 20px 5px #00ffff4d;
}

.staking-monster-bg {
  background: url("staking-monster.5762f990.png") center / cover no-repeat;
}

.text-shadow {
  text-shadow: 0 0 2px #140f3899;
}

.battleTabCounter {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  margin-left: .25rem;
  padding: .125rem .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.text-orange-500 .battleTabCounter {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.wallet-options-wrapper {
  margin-bottom: 0 !important;
}

.options-list-section:first-child {
  margin-bottom: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  gap: 0 !important;
}

.nws-modal-wrapper .nws-modal .modal-left {
  padding: 24px !important;
}

.nws-modal-overlay {
  background: #090029b3 !important;
}

.one-battle {
  background: linear-gradient(90deg, #150b49 0%, #0a012c 100%);
}

.win-lose {
  width: 0;
  height: 0;
  z-index: 2;
  border-top: 0 solid #0000;
  border-bottom: 64px solid #ef4444;
  border-left: 40px solid #0000;
  border-right: 0 solid #0000;
  position: absolute;
  top: 0;
  left: -40px;
}

.bg-green-500 .win-lose {
  border-color: #0000 #0000 #22c55e;
}

.options-list-section-header {
  display: none;
}

.options-list-section {
  margin-bottom: 0 !important;
}

.rotate-image {
  animation: 1.1s linear infinite spin;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:bg-orange-600\/30:hover {
  background-color: #ea580c4d;
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.hover\:bg-mainLight:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 18 94 / var(--tw-bg-opacity));
}

.hover\:bg-main:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 6 53 / var(--tw-bg-opacity));
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.hover\:bg-main\/50:hover {
  background-color: #0c063580;
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.hover\:fill-red-700:hover {
  fill: #b91c1c;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.hover\:text-sky-200:hover {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.hover\:text-sky-600:hover {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:text-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.hover\:text-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.peer:checked ~ .peer-checked\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.peer:focus ~ .peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }

  .dark\:bg-main {
    --tw-bg-opacity: 1;
    background-color: rgb(12 6 53 / var(--tw-bg-opacity));
  }

  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }

  .peer:focus ~ .dark\:peer-focus\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:right-8 {
    right: 2rem;
  }

  .sm\:left-28 {
    left: 7rem;
  }

  .sm\:right-28 {
    right: 7rem;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:mr-12 {
    margin-right: 3rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-\[740px\] {
    width: 740px;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-8\/12 {
    width: 66.6667%;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-\[816px\] {
    width: 816px;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-\[920px\] {
    width: 920px;
  }

  .sm\:w-1\/5 {
    width: 20%;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-\[900px\] {
    width: 900px;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-\[800px\] {
    width: 800px;
  }

  .sm\:max-w-\[40px\] {
    max-width: 40px;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pt-28 {
    padding-top: 7rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:left-32 {
    left: 8rem;
  }

  .md\:right-32 {
    right: 8rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:ml-0\.5 {
    margin-left: .125rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mr-28 {
    margin-right: 7rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:basis-9\/12 {
    flex-basis: 75%;
  }

  .md\:basis-3\/12 {
    flex-basis: 25%;
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:-mt-28 {
    margin-top: -7rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-3 {
    height: .75rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-\[900px\] {
    width: 900px;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-\[1060px\] {
    width: 1060px;
  }

  .lg\:w-\[840px\] {
    width: 840px;
  }

  .lg\:basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:basis-4\/12 {
    flex-basis: 33.3333%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 1280px) {
  .xl\:ml-1 {
    margin-left: .25rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:basis-3\/4 {
    flex-basis: 75%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .\32 xl\:mr-10 {
    margin-right: 2.5rem;
  }

  .\32 xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .\32 xl\:mt-16 {
    margin-top: 4rem;
  }

  .\32 xl\:w-3\/4 {
    width: 75%;
  }

  .\32 xl\:basis-1\/4 {
    flex-basis: 25%;
  }

  .\32 xl\:pl-14 {
    padding-left: 3.5rem;
  }
}

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: #0000;
}

/*# sourceMappingURL=index.cb767413.css.map */
